@import "assets/styles/colors";

.comment-list {
  overflow-y: auto;

  &__content {
    overflow-y: auto;
  }

  &__empty, &__loading {    
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 16px 0px;
  }

  &__empty {
    display: flex;
    justify-content: center;
  }

  &__scroll-bottom {
    position: sticky;
    bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: min-content;

    button {
      color: $color-brand-primary-default;
      width: min-content;
      border: none;
      background: none;

      svg {
        background-color: $color-white;
        border-radius: 50%;
      }

      &:hover {
        // Hover changes needed because of CustomPagination.scss selector
        // svg in "svg path" needed for specificity, alternative would be to add class to button
        svg path {
          stroke: none;
        }
      }
    }
  }
}
