@import "assets/style.scss";

%control-border {
    border: 1px solid $color-gray-lightest;
    border-radius: 8px;
}

%control-border-focused {
    border: 1px solid $color-brand-primary-default !important;
    box-shadow: none;
}

%control-border-disabled {
    border: none;
    box-shadow: none;
}

%control-border-valid {
    border: 1px solid $color-success;
    box-shadow: none;
}

%control-border-invalid {
    border: 1px solid $color-error;
    box-shadow: none;
}

.custom-select-field {
    margin-bottom: 0.5rem !important;

    > label {
        @extend .bold-12;
        color: $color-gray !important;

        &.is-disabled {
            color: rgba($color-gray-light, 0.8) !important;
        }

        &.custom-is-valid {
            color: rgba($color-success, 0.8) !important;
        }

        &.custom-is-invalid {
            color: rgba($color-error, 0.8) !important;
        }

        &.is-active {
            color: $color-brand-primary-default !important;
        }

        &.custom-required:after {
            content: " *";
            color: $color-error;
        }
    }

    .ppe-select-container {
        @extend .regular-14;

        .ppe-select__ {
            &placeholder,
            & input {
                @extend .regular-14;
                opacity: 0.5;
            }

            &control {
                @extend .regular-14;
                min-height: 40px;
                background: $color-extra-light;
                border-radius: 8px;

                @extend %control-border;
                &:hover {
                    @extend %control-border;
                }

                & input {
                    @extend .regular-14;
                }

                &--is-focused,
                &--menu-is-opened {
                    @extend %control-border-focused;
                    &:hover {
                        @extend %control-border-focused;
                    }

                    & input {
                        color: $color-black !important;
                    }
                }

                &--is-disabled {
                    @extend %control-border-disabled;
                    background-color: rgba($color-gray-lightest, 0.4);

                    &placeholder,
                    & input {
                        @extend .regular-14;
                        opacity: 0.5;
                    }
                }
            }

            &indicator-separator {
                display: none;
            }

            &menu {
                z-index: 10;
            }
        }

        &.custom-is-invalid {
            .ppe-select__control {
                @extend %control-border-invalid;
            }
        }

        &.custom-is-valid {
            .ppe-select__control {
                @extend %control-border-valid;
            }
        }

        &.is-active {
            .ppe-select__control {
                @extend %control-border-focused;
            }
        }
    }
}
