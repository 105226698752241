@import "assets/styles/colors";

.document-cell__link {
    color: $color-info;

    &:hover {
        color: $color-info;
        text-decoration: underline;

        path {
            stroke: $color-info;
        }
    }
}

.document-cell__download-icon {
    margin-left: 4px;
    vertical-align: sub;
    height: 14px;
    width: 14px;
}
