@import "assets/styles/colors.scss";

.custom-checkbox-indeterminate {
    circle {
        //stroke: $color-info !important;
        fill: $color-gray-light !important;
    }

    path {
        //stroke: $color-info !important;
    }
}
