.additional-deposit-report-line-list {
    .report-line-list__table {
        thead {
            tr {
                height: 40px !important;

                th {
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                }
            }
        }
        
        > tbody > tr > td {
            &.reset-expansion-style {
                padding: 0px !important;

                .row-expansion-style {
                    padding: 0px;
                }
            }
        }
    }
}
