@import "assets/style.scss";

button.checkbox-button {
    @extend .regular-12;
    background-color: $color-white !important;
    color: $color-gray-dark !important;
    border: 1px solid $color-divider;
    box-shadow: none !important;

    display: flex !important;
    justify-content: left;
    align-items: center !important;
    color: #4B506D !important;
    border-radius: 100px !important;
    position: relative;

    svg {
        float: right !important;
        position: absolute;
        right: 8px;
    }

    &.checkbox-button-selected, &:hover {
        border-color: $color-brand-primary-default !important;
    }   

    &:disabled {
        border-color: $color-gray-lightest !important;
        background-color: $color-gray-lightest !important;
        opacity: 1;
        cursor: not-allowed;

        .selected-svg {
            circle {
                fill: $color-gray !important;
            }
        }

        .unselected-svg {
            circle {
                fill: $color-white !important;
            }
        }

        .disabled-unselected-svg {
            rect {
                fill: $color-gray;
            }

            path {
                stroke: $color-white;
            }
        }
    }

    &.custom-is-invalid {
        border-color: $color-error;
    }

    
    
}