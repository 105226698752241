@import "assets/style.scss";

.users-card {
    .card-header {
        border-radius: 8px;
    }

    .users-table {
   
        tbody > tr {
            cursor: pointer;
    
            > td:first-child {
                padding-left: 32px !important;
                padding-right: 32px !important;
            }
        }
    
        th {
            @extend .bold-14;
            border-top: 0;
            color: $color-gray !important;
            padding: 14px !important;
        }
    
        tbody tr {
            font-family: Muli, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: #4B506D;
        }
    }
}