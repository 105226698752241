@import "assets/styles/typography.scss";
@import "assets/styles/colors.scss";

.package-conditions-wrapper {
    svg {
        height: 20px;
        width: 20px;
        &.icon-problem {
            path {
                fill: #F12B2C;
            }
        }
    }
}

.bs-tooltip-auto[x-placement^="top"] {
    .package-conditions-tooltip-arrow::before {
        border-top-color: $color-white;
    }
}

.bs-tooltip-auto[x-placement^="bottom"] {
    .package-conditions-tooltip-arrow::before {
        border-bottom-color: $color-white;
    }
}

.bs-tooltip-auto[x-placement^="left"] {
    .package-conditions-tooltip-arrow::before {
        border-left-color: $color-white;
    }
}

.bs-tooltip-auto[x-placement^="right"] {
    .package-conditions-tooltip-arrow::before {
        border-right-color: $color-white;
    }
}

.package-conditions-tooltip-inner {
    @extend .regular-10;
    padding: 8px 16px;
    background-color: $color-white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
}
