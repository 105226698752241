@import "assets/style.scss";

.repair-info-modal, .repair-delete-modal {

    .close-icon path {
        stroke: $color-gray;
    }

    .close-modal-button {
        cursor: pointer;
    }
}

.delete-repair-report-icon path {
    fill: $color-error;
}