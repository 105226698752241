@import "assets/styles/colors";
@import "assets/styles/typography.scss";

.comment-create__input {
  .grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
  }
  .grow-wrap::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";
  
    /* This is how textarea text behaves */
    white-space: pre-wrap;
  
    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }
  .grow-wrap > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    &:focus, &:active {
      outline-color: $color-brand-primary-default;
    }
  }
  .grow-wrap > textarea,
  .grow-wrap::after {
    /* Identical styling required!! */
    @extend .regular-14;
    /* custom line-height makes scrollbar appear too fast */
    line-height: revert !important;

    background-color: $color-white;
    padding: 8px 32px 8px 12px;
    border-radius: 15px;
    
    width: 100%;
    max-width: 100%;
    max-height: 100px;
    overflow: auto;
  
    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
  }
}

.comment-create__submit {
  position: absolute;
  right: 30px;
  place-self: center;

  & button {
    color: $color-brand-primary-default;
    width: min-content;
    border: none;
    background: none;
    
    &:disabled {
      color: $color-gray-light
    }
  }
}
