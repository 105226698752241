@import "assets/style.scss";

.add-user-modal {
    .modal-content {
        border-radius: 12px !important;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    }

    .modal-body {
        padding: 32px;
    }

    .modal-title {
        @extend .bold-19;
        color: $color-black !important;
    }

    .modal-close-button {
        @extend .regular-12;
        cursor: pointer;
        color: $color-gray !important;
        vertical-align: middle;

        path {
            stroke: $color-gray;
        }
    }

    .content-section {
        margin-top: 48px;
    }
}