@import "assets/styles/colors.scss";

html {
    height: 100% !important;
}

#content {
    width: 100%;
    position: relative;
    margin-right: 0;
    background: #f7f8fc;
    min-height: 100vh !important;
}

.page-header {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #252733;
    height: auto;
}

@media (max-width: 992px) {
    .page-header {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.2px;
    }
}

@media (max-width: 768px) {
    .page-header {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.2px;
    }
}

.navbar {
    min-height: 80px;
}

.navbar-title > li {
    min-height: 64px;
}

@media (max-width: 768px) {
    .navbar {
        height: auto;
    }
}

.card {
    background: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    border: 0;
}

.card-body {
    padding: 24px;
}

/* Sidebar Styles */
.sidebar {
    z-index: 1000;
    position: fixed;
    left: 0;
    width: 250px;
    height: 100%;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
}

.sidebar.minimized-sidebar {
    width: auto;
}

.nav-sidebar {
    background: #ffffff;
    padding-top: 42px;
}

.sidebar header {
    background: #ffffff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
    font-size: 20px;
    height: 80px;
    line-height: 52px;
    /*padding: 40px 0 56px 32px;*/
    padding-left: 32px;
    display: flex;
    align-items: center;
}

header span {
    padding-left: 8px;
    text-decoration: none;
    font-family: Muli, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #494949;
}

header .nav-link:hover {
    color: #fff;
}

.sidebar-logout {
    display: flex;
    align-items: center;
    background: none;
    padding: 16px 32px !important;
    min-height: 54px;

    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #c5c7cd;

    &:hover {
        text-decoration: none;
        color: $color-brand-primary-default;
    }
}

.sidebar-link {
    display: flex;
    align-items: center;
    background: none;
    padding: 16px 32px !important;
    min-height: 54px;

    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #c5c7cd;

    &.active {
        background: #f7f8fc;
        border-left: 3px solid #46a72a;
        padding-left: 29px !important;
        color: $color-brand-primary-default;

        path {
            fill: $color-brand-primary-default;
            opacity: 1;
        }
    }

    &:hover {
        text-decoration: none;
        color: $color-brand-primary-default;

        path {
            fill: $color-brand-primary-default;
            opacity: 1;
        }
    }
}

.sidebar-language-item {
    padding-left: 64px !important;
}

.sidebar-logout-item {
    padding-left: 32px !important;
}

.menu-item {
    margin-left: 16px;
}

/*.sidebar-link svg{*/
/*    margin-right: 24px;*/
/*}*/

.custom-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    background-color: $color-brand-secondary-light;
    padding: 16px 0px;

    a {
        color: $color-gray-dark;

        svg {
            margin-right: 8px;
            padding: 4px;
            background-color: white;
            border-radius: 50%;
            height: 24px;
            width: 24px;

            path {
                fill: $color-gray-dark;
                opacity: 1;
            }
        }
    }
}
