@import "assets/style.scss";

.invoice-preview-modal {
    .modal-content {
        border-radius: 12px !important;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    }

    .modal-body {
        padding: 32px;
    }

    .header-section {
        margin-bottom: 24px;
    }

    .content-section {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .modal-title {
        @extend .bold-24;
        color: $color-black !important;
    }

    .modal-close-button {
        @extend .regular-12;
        cursor: pointer;
        color: $color-gray !important;
        vertical-align: middle;

        path {
            stroke: $color-gray;
        }
    }

    .invoice-preview-receiver-section {
        margin-top: 48px;
        margin-bottom: 48px;
    }

    .invoice-preview-table {
        //border-bottom: 1px solid $color-divider;
        
        thead {
            border-bottom: 1px solid $color-gray-dark;
            tr.invoice-preview-table-header {
                th {
                    border-width: 0px !important;
                    padding: 0px !important;
                    padding-bottom: 16px !important;
                }
            }
        }

        tbody {
            border-bottom: 1px solid $color-divider;

            tr.invoice-preview-table-row {
                height: auto !important;
                td {
                    border-width: 0px !important;
                    padding: 8px 0px !important;
                }

                &:first-child {
                    td {
                        padding-top: 16px !important;
                    }
                }

                &:last-child {
                    td {
                        padding-bottom: 16px !important;
                    }
                }
                
            }
        }

        tfoot {
            tr {
                th {
                    border-width: 0px !important;
                    padding: 16px 0px !important;

                    
                }

                .bottom-divider {
                    border-bottom: 1px solid $color-divider !important;
                }
            }
        }
    }
}