@import "assets/styles/colors.scss";

.additional-deposit-reports {    
    table.additional-deposit-reports__table {
        thead {
            tr {
                height: 40px !important;
    
                th {
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                }
            }
        }
        
        > tbody > tr > td {
            &.reset-expansion-style {
                padding: 0px !important;
    
                .row-expansion-style {
                    padding: 0px;
                }
            }
        }

        .client-col,
        .client-col-header {
            width: 200px;
        }
    
        .period-col,
        .period-col-header {
            width: 150px;
    
            .expand-icon path {
                fill: $color-info;
            }
        }

        .status-col,
        .status-col-header {
            width: 150px;
            text-align: center;
    
            .status-cell {
                width: fit-content;
                display: inline-block;
            }
        }

        .invoice-col,
        .invoice-col-header {
            width: 150px;
        }
    
        .action-col,
        .action-col-header {
            width: 25px;
            text-align: right;
        }
    }
}
