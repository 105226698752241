@import "assets/styles/colors.scss";

.admin-sales-reports-card-body {
    padding: 0px;
}

.admin-sales-reports-table-wrapper {
    min-height: 230px;

    table.admin-sales-reports-table {
        > tbody > tr > td {
            &.reset-expansion-style {
                padding: 0px !important;

                .row-expansion-style {
                    padding: 0px;
                }
            }
        }

        .period-col,
        .period-col-header {
            width: 210px;

            .expand-icon path {
                fill: $color-info;
            }
        }

        .submitted-col,
        .submitted-col-header {
            width: 110px;
        }

        .status-col,
        .status-col-header {
            width: 180px;
            text-align: center;

            .status-cell {
                width: 180px;
                display: inline-block;
            }
        }

        .invoice-col,
        .invoice-col-header {
            width: 100px;
        }

        .action-col,
        .action-col-header {
            width: 25px;
            text-align: right;
        }
    }
}
.sales-repair-table {
    margin-bottom: 0px;
}

.sales-expanded-row {
    background-color: $color-info-light;
}
