@import "assets/styles/colors.scss";

.packages-card {
    border-radius: 0 !important;

    .card-body {
        overflow-y: auto;
    }
}

table.packages-table {
    table-layout: auto;
}

.page-item > a {
    color: #9FA2B4 !important;
}

.active.page-item > a {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;letter-spacing: 0.3px;
    background-color: #9FA2B4 !important;
    border-color: #9FA2B4 !important;
    color: #ffffff !important;
}

#pageDropDown {
    background-color: #9FA2B4 !important;
    border-color: #9FA2B4 !important;
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;letter-spacing: 0.3px;
    color: #ffffff;
}

.pagination {
    float: right;
}
