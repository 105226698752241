.summary-report-card {
    .card-body {
        overflow-y: auto !important;

        .react-bootstrap-table {
            
            .summary-details-table {
                table-layout: auto;
            }
       
        }
    }
}