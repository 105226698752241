/* Brand colors */
$color-brand-primary-default: #46a72a;
$color-brand-primary-dark: #297a11;
$color-brand-primary-light: #d0edc9;
$color-brand-secondary-default: #5e5e5e;
$color-brand-secondary-dark: #272626;
$color-brand-secondary-light: #e4e4e4;

/* Grayscale colors */
$color-black: #252733;
$color-gray-dark: #4b506d;
$color-gray: #9fa2b4;
$color-gray-light: #c5c7cd;
$color-gray-lightest: #f0f1f7;
$color-extra-light: #fcfdfe;
$color-white: #ffffff;
$color-divider: #dfe0eb;
$color-divider-graph: #ebedf0;
$color-bg: #f7f8fc;

/* Other colors */
$color-default: #4b506d;
$color-default-light: #f7f8fc;
$color-info: #297cfd;
$color-info-light: #e7f5ff;
$color-success: #13cd90;
$color-success-light: #e4fff6 !important;
$color-warning: #fcc411;
$color-warning-light: #fff4d0;
$color-error: #f12b2c;
$color-error-light: #ffeded;

.color {
    &-brand-primary-default {
        color: $color-brand-primary-default;
    }

    &-brand-primary-default-bg {
        background: $color-brand-primary-default;
    }

    &-brand-secondary-default {
        color: $color-brand-secondary-default;
    }

    &-brand-secondary-default-bg {
        background: $color-brand-secondary-default;
    }

    &-brand-primary-light {
        color: $color-brand-primary-light;
    }

    &-brand-primary-light-bg {
        background: $color-brand-primary-light;
    }

    &-brand-primary-dark {
        color: $color-brand-primary-dark;
    }

    &-brand-primary-gray-bg {
        background: $color-brand-primary-dark;
    }

    &-brand-secondary-dark {
        color: $color-brand-secondary-dark;
    }

    &-brand-secondary-dark-bg {
        background: $color-brand-secondary-dark;
    }

    &-brand-secondary-light {
        color: $color-brand-secondary-light;
    }

    &-brand-secondary-light-bg {
        background: $color-brand-secondary-light;
    }

    &-black {
        color: $color-black !important;
    }

    &-black-bg {
        background: $color-black;
    }

    &-gray-dark {
        color: $color-gray-dark;
    }

    &-gray-dark-bg {
        background: $color-gray-dark;
    }

    &-gray {
        color: $color-gray !important;
    }

    &-gray-bg {
        background: $color-gray;
    }

    &-gray-light {
        color: $color-gray-light;
    }

    &-gray-light-bg {
        background: $color-gray-light;
    }

    &-gray-lightest {
        color: $color-gray-lightest;
    }

    &-gray-lightest-bg {
        background: $color-gray-lightest;
    }

    &-extra-light {
        color: $color-extra-light;
    }

    &-extra-light-bg {
        background: $color-extra-light;
    }

    &-white {
        color: $color-white;
    }

    &-white-bg {
        background: $color-white;
    }

    &-divider {
        color: $color-divider;
    }

    &-divider-bg {
        background: $color-divider;
    }

    &-divider-graph {
        color: $color-divider-graph;
    }

    &-divider-graph-bg {
        background: $color-divider-graph;
    }

    &-bg {
        color: $color-bg;
    }

    &-bg-bg {
        background: $color-bg;
    }

    &-info {
        color: $color-info !important;
    }

    &-info-bg {
        background: $color-info;
    }

    &-info-light {
        color: $color-info-light;
    }

    &-info-light-bg {
        background: $color-info-light;
    }

    &-success {
        color: $color-success;
    }

    &-success-bg {
        background: $color-success;
    }

    &-success-light {
        color: $color-success-light;
    }

    &-success-light-bg {
        background: $color-success-light;
    }

    &-warning {
        color: $color-warning;
    }

    &-warning-bg {
        background: $color-warning;
    }

    &-warning-light {
        color: $color-warning-light;
    }

    &-warning-light-bg {
        background: $color-warning-light;
    }

    &-error {
        color: $color-error !important;
    }

    &-error-bg {
        background: $color-error !important;
    }

    &-error-light {
        color: $color-error-light;
    }

    &-error-light-bg {
        background: $color-error-light !important;
    }
}
