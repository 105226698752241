@import "assets/style.scss";

@mixin placeholder-color($placeholder-color) {
    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $placeholder-color !important;
        opacity: 1; /* Firefox */
    }
}


.custom-input-field {
    margin-bottom: 0.5rem !important;

    /* TODO Placeholders color setting didn't seem to work, needs more looking into */
    > label {
        @extend .bold-12;
        color: $color-gray !important;
        
        &.is-disabled {
            color: rgba($color-gray-light, 0.8) !important;
        }

        &.custom-is-valid {
            color: rgba($color-success, 0.8) !important;
        }
        
        &.custom-is-invalid {
            color: rgba($color-error, 0.8) !important;
        }

        &.is-active {
            color: $color-brand-primary-default !important;
        }

        &.custom-required:after {
            content: " *";
            color: $color-error;
        }
    }

    > input {
        @extend .regular-14;
        background: $color-extra-light;
        border: 1px solid $color-gray-lightest;
        height: 40px;
        border-radius: 8px;
        box-shadow: none !important;

        &:disabled {
            background-color: rgba($color-gray-lightest, 0.4);
            border-color: rgba($color-gray-lightest, 0);
            /* for placeholder: color: rgba($color-gray-dark, 0.5) !important; */
        }

        &.custom-is-valid {
            border-color: $color-success;
        }
        
        &.custom-is-invalid {
            border-color: $color-error;
        }
        
        &.is-active {
            background: $color-extra-light;
            color: $color-black !important;
            border-color: $color-brand-primary-default;
        }

        &::placeholder {
            color: $color-gray-dark !important;
        }
    }

    .custom-valid-feedback {
        @extend .regular-14;
        color: $color-success;
    }

    .custom-invalid-feedback {
        @extend .regular-14;
        color: $color-error;
    }
}