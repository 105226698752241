@import "assets/style.scss";


%alert-body {
    .alert-body {
        padding: 8px 16px;
        min-height: 40px;
    }

    &-icon .alert-body {
        padding: 16px;
        min-height: 48px;
    }
} 

.alert-card.custom-alert {
    @extend .semibold-12;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 0px;

    .alert-body {
        padding: 16px;
    }

    .alert-icon {
        margin-right: 0px;
    }


    &-default {
        background-color: $color-default-light;
        color: $color-default;
        @extend %alert-body;
        
        .alert-icon {
            display: none;
        }

        &-icon {
            background-color: $color-default-light;
            
            rect {
                fill: $color-gray;
            }
            
        }
    }

    &-success {
        background-color: $color-success-light;
        color: $color-success;
        @extend %alert-body;

        .alert-icon {
            display: none;
        }

        &-icon {
            background-color: $color-success-light;
            
            rect {
                fill: $color-success;
            }
        }
    }

    &-info {
        background-color: $color-info-light;
        color: $color-info;
        @extend %alert-body;

        .alert-icon {
            display: none;
        }

        &-icon {
            background-color: $color-info-light;
            
            rect {
                fill: $color-info;
            }
        }
    }

    &-warning {
        background-color: $color-warning-light;
        color: $color-warning;
        @extend %alert-body;
        
        .alert-icon {
            display: none;
        }

        &-icon {
            background-color: $color-warning-light;
            @extend %alert-body-icon;

            rect {
                fill: $color-warning;
            }
        }
    }

    &-error {
        background-color: $color-error-light !important;
        color: $color-error;
        @extend %alert-body;

        .alert-icon {
            display: none;
        }

        &-icon {
            background-color: $color-error-light;

            rect {
                fill: $color-error;
            }
        }
    }
}