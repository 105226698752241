.profile-card .card-body {
    padding: 32px;
}

.profile-form {
    padding: 8px 0;
}

.label {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #9FA2B4;
}

.input {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #4B506D;
}

.input::placeholder {
    opacity: 0.5;
}

.input.disabled {
    background: #F0F1F7;
    opacity: 0.4;
}

.checkbox-button-user-role {
    min-width: 220px;
    justify-content: center !important;
}