@import "assets/styles/colors.scss";


@mixin with-count($n) {
    @if $n == 1 {
      &:first-child:nth-last-child(1) {
        @content;
      }
    }
    @else {
      &:first-child:nth-last-child(#{$n}),
      &:first-child:nth-last-child(#{$n}) ~ & {
        @content;
      }
    }
  }

.package-test-item {
    padding: 16px 16px 0px 16px;
}

.package-test-collapse-arrow {
    path {
        stroke: $color-info;
    }
}

.package-test-wrapper {
    border-bottom: 1px solid $color-divider;
}

.package-test-status-select {
    width: 80px;
}

.selected-sc-list {
    .selected-sc-item {
        align-items: center;
        border-bottom: 1px solid $color-bg;
        margin: 0px;
        min-height: 40px;

        @include with-count(1) {
            margin-top: 29px;
        }

        @include with-count(2) {
            &:first-child {
                min-height: 29px;
            }
        }
        
        @include with-count(3) {    
            &:first-child {
                min-height: 29px;
            }
        }

        .selected-sc-remove-col {
            float: right;
            text-align: right;
            padding-left: 0px;
            padding-right: 12px;
        
            .selected-sc-remove-icon {
                cursor: pointer;

                rect {
                    fill: $color-error;
                }

                path {
                    stroke: $color-white;
                }
            }
        }
    }
}