@import "assets/style.scss";

.forgot-password-card {
    max-width: 550px;
    margin: 0 auto;

    .card-body {
        padding: 32px;
    }

    .forgot-password-heading {
        @extend .bold-24;
        color: $color-black !important;

        margin-bottom: 12px;
    }

    .forgot-password-select-type {
        @extend .regular-14;
        color: $color-gray;
        margin-bottom: 48px;
    }
}
