@import "assets/style.scss";

.sync-additional-deposit-report-modal {
    .modal-content {
        border-radius: 12px !important;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    }

    .modal-body {
        padding: 32px;
    }

    .header-section {
        margin-bottom: 16px;
        padding-bottom: 8px;
    }

    .modal-title {
        @extend .bold-19;
        color: $color-black !important;
    }

    .modal-close-button {
        @extend .regular-12;
        cursor: pointer;
        color: $color-gray !important;

        path {
            stroke: $color-gray;
        }
    }

    .modal-cancel-button {
        @extend .semibold-14;
        color: $color-brand-primary-default;
    }

    .modal-import-button {
        @extend .semibold-14;
        min-width: 120px;

        &:disabled {
            cursor: not-allowed;
        }
    }
}
