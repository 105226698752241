@import "assets/styles/colors";
@import "assets/styles/typography";
@import "assets/styles/buttons";
@import "assets/styles/datepicker";
@import "assets/styles/table";

body {
    overflow-x: hidden;
    font-family: $font-family;
    font-size: 16px;
}

/* Toggle Styles */

#viewport {
    height: 100% !important;
    padding-left: 250px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#content {
    width: 100%;
    /*height: 100% !important;*/
    position: relative;
    padding: 0 32px 0 32px;
}

.content {
    margin-top: 42px;
}

@media (max-width: 992px) {
    .content {
        margin-top: 16px;
    }
}

/* TOP NAVBAR */
.navbar > ul {
    height: 100%;
    display: flex;
    align-items: center;
}

.navbar > ul > li {
    height: 32px;
    display: flex;
    align-items: center;
}

.navbar-element {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    height: 100% !important;
    display: flex;
    align-items: center;
}

.navbar-user {
    padding: 0 16px 0 16px;
    border-left: 1px solid $color-divider;
    color: $color-black !important;
}

.navbar-log-out {
    padding: 0 0 0 16px;
    border-left: 1px solid $color-divider;
    color: $color-info !important;

    svg {
        > path {
            fill: $color-info;
        }
    }
}

/* Typography */
.heading {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: $color-black;
}

/* End Typography */

.pointer {
    cursor: pointer;
}

/* BADGES */
.badge-danger {
    background: #ffeded;
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 9px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #f12b2c;
    padding: 6px 12px 6px 12px;
    border-radius: 8px;
}

.badge-warning {
    background: #fff4d0;
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 9px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #fcc411;
    padding: 6px 12px 6px 12px;
    border-radius: 8px;
}

.badge-success {
    background: #e4fff6;
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 9px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #13cd90;
    padding: 6px 12px 6px 12px;
    border-radius: 8px;
}

.badge-primary {
    background: #e7f5ff;
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 9px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #297cfd;
    padding: 6px 12px 6px 12px;
    border-radius: 8px;
}

// INPUTS
label {
    @extend .regular-14;
    text-transform: uppercase !important;
    color: #9fa2b4 !important;
}

.input-md {
    min-height: 38px !important;
}

input {
    @extend .regular-12;
    color: #4b506d !important;
    line-height: 24px;

    &::placeholder {
        opacity: 0.5 !important;
    }

    &.disabled {
        background: #f0f1f7 !important;
        opacity: 0.4 !important;
    }
}

.select {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 20px;
    letter-spacing: 0.3px !important;
    min-height: 40px !important;
    color: #4b506d !important;
}

.select > div {
    min-height: 40px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

// ENDINPUTS

/* ICONS */

.icon-default {
    path {
        fill: $color-gray-light;
    }

    &:hover path {
        fill: $color-brand-primary-default;
    }
}

.icon-left path {
    stroke: $color-info;
}

/* END ICONS */

.cursor-pointer {
    cursor: pointer;
}

// CARDS
//.card-half-page {
//  max-width: 533px;
//}
//
//.card-full-page {
//  max-width: 1096px;
//}

.flipped-horizontal-scrollbar,
.flipped-horizontal-scrollbar > * {
    /* Add to element to have its scrollbar on top */
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg); /* IE 9 */
    -webkit-transform: rotateX(180deg); /* Safari and Chrome */
}

.bottom-border {
    border-bottom: 1px solid #dfe0eb !important;
}

.no-background {
    background: none !important;
}
