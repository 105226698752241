.min-height-40 {
    min-height: 40px !important;
}

.sales-search-card {
    .search-period-text {
        display: flex;
        align-items: center;
    }

    .search-period-text,
    .search-input, 
    .search-button {
        @extend .min-height-40;
    }
}