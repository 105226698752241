.login-card {
    max-width: 550px;
    margin: 0 auto;
}

.login-card-body {
    padding: 32px;
}

.login-heading {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #252733;
    margin-bottom: 12px;
}

.login-select-type {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #9fa2b4;
}

.login-credentials {
    margin-top: 32px;
    text-align: left;
}

.login-credential {
    margin-bottom: 6px;
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    color: #9fa2b4;
}

.login-input {
    background: #fcfdfe;
    border: 1px solid #f0f1f7;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 24px;
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #4b506d;
    opacity: 0.4;
}

.login-alert {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #4b506d;
}

.login-option {
    min-width: 100px;
}
