@import "assets/styles/colors";

%status-type-shared {
    border-radius: 8px !important;
    align-items: center !important;
    text-align: center !important;
    padding: 5px 12px !important;
    max-width: 180px !important;
}

.status-type {
    &-default {
        @extend %status-type-shared;
        background: $color-gray-lightest;
        > span {
            color: $color-gray;
        }
    }
    
    &-info {
        @extend %status-type-shared;
        background: $color-info-light;
        > span {
            color: $color-info !important;
        }
    }

    &-success {
        @extend %status-type-shared;
        background: $color-success-light;
        > span {
            color: $color-success !important;
        }
    }
    
    &-warning {
        @extend %status-type-shared;
        background: $color-warning-light;
        > span {
            color: $color-warning !important;
        }
    }
    
    &-error {
        @extend %status-type-shared;
        background: $color-error-light;
        > span {
            color: $color-error !important;
        }
    }
}