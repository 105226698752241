.package-conditions-select-wrapper {
    svg.package-condition-icon {
        height: 20px;
        width: 20px;

        path {
            fill: #f12b2c;
        }
    }
}
