.selected-applications-table__wrapper {
    max-height: 500px;
}

table.selected-applications-table {
     // border collapse and spacing here help with fixed header borders not disappearing on scroll.
    border-collapse: separate;
    border-spacing: 0px;

    thead {
        tr {
            height: 40px !important;

            th {
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }
        }
    }

    .package-condition-col {
        min-width: 120px;
    }
}
