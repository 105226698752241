@import "assets/styles/colors";
@import "assets/styles/typography";

.additional-deposit-report-actions {
    .dropdown-menu {
        border-radius: 4px;
    }

    .dropdown-item {
        height: 46px;
        min-width: 180px;
    }
    

    .dropdown-menu {
        padding: 0px;
    }

    .dropdown-item:hover {
        color: $color-white !important;
        background-color: $color-brand-primary-default;
    }

    &-icon-button {
        padding: 0px;
        text-align: center;
        width: 24px;
        height: 24px;

        > svg {
            vertical-align: baseline;
        }
    }

    .details-button {
        min-width: 150px;
    }
}
