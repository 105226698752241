@import "assets/styles/colors.scss";

// TODO: Make it more specific. Also check if anything else seems to rely on it for hover.
button:hover:not(:disabled), .selected-size-per-page:hover:not(:disabled) {
    color:  $color-brand-primary-default;
    
    path {
        stroke: $color-brand-primary-default;
    }

} 
