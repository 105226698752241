.add-package-card {
    min-height: 600px;
}

.modal-header {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #252733;
    border-bottom: none;
    padding: 32px 32px 0 32px;
}

.package-modal-body {
    padding: 0;
}

.add-package-btn {
    margin-top: 28px;
}

.card-footer {
    border-top: none;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

.footer-button {
    padding: 12px 24px 12px 24px;
}