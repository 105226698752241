@import "assets/styles/colors.scss";

.user-mode-selection {
    &:hover,
    &:focus,
    &:active {
        .user-mode-selection-selected {
            color: $color-brand-primary-default !important;
        }
    }

    .user-selection-option {
        &:hover {
            color: $color-brand-primary-default !important;
            background-color: $color-gray-lightest;

            > span {
                color: $color-brand-primary-default !important;
            }
        }

        &:active,
        &:focus {
            color: $color-brand-primary-default !important;
            background-color: $color-gray-light;
            outline-width: 0px;

            > span {
                color: $color-brand-primary-default !important;
            }
        }
    }

    .user-selection-option {
        padding-left: 40px;
    }

    .dropdown-header {
        cursor: auto;
        &:focus {
            outline-width: 0px;
        }
    }
}

.user-mode-selection-selected {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
