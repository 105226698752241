.card-header {
    padding: 32px;
    background: #ffffff;
    border-bottom: none;
}

//.card-body {
//  padding-left: 32px !important;
//  padding-right: 32px !important;
//}

.add-new-package {
    padding-top: 20px;
    padding-bottom: 20px;
}

.package-row,
.latest-report-row {
    border-top: 1px solid #dfe0eb;
    border-bottom: 1px solid #dfe0eb;
    padding-top: 20px;
    padding-bottom: 20px;

    &:last-child {
        border-top: none;
        border-bottom: none;
    }

    &:first-child {
        border-top: 1px solid #dfe0eb;
        border-bottom: none;
    }
}

.latest-report-row:hover {
    background-color: #fcfdfe;
    cursor: pointer;
}

.statistics-row {
    height: 100%;
}

.chart-column {
    @media (min-width: 768px) {
        border-right: 1px solid #dfe0eb;
    }
    padding: 32px;
}

.recharts-wrapper {
    margin-right: 0;
}

.statistics-row > div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dfe0eb;
}
.statistics-row > div:last-child {
    border-bottom: none;
}

line {
    stroke: #ebedf0;
}

.recharts-container {
    height: 350px !important;
}

.recharts-default-legend {
    text-align: right !important;
}
