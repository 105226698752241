@import "assets/styles/colors.scss";

.notification-container .notification-number {
    position: absolute;
    background: #fb4f39;
    border-radius: 50%;
    color: white;
    font-size: 9px;
    top: 8px;
    right: 6px;
    padding: 0;
    padding-top: 3px;
    width: 15px;
    height: 15px;

    &.notification-number-limit {
        font-size: 8px;
        padding-top: 4px;
        width: 16px;
        height: 16px;
    }
}

.notification-dropdown-menu {
    width: 300px;
}

.all-notifications-link {
    margin-top: -0.7rem;
}

.notification-empty-message {
    margin-bottom: 12px;
    &:hover {
        color: black !important;
        background-color: white !important;
    }
}

.notification-option {
    &:hover {
        color: $color-brand-primary-default !important;
        background-color: white !important;

        // > span {
        //     color: $color-brand-primary-default !important;
        // }
    }

    &:active,
    &:focus {
        color: $color-brand-primary-default !important;
        background-color: $color-gray-light;
        outline-width: 0px;

        > span {
            color: $color-brand-primary-default !important;
        }
    }

    .dropdown-header {
        cursor: auto;
        &:focus {
            outline-width: 0px;
        }
    }

    .notification-link {
        &:hover {
            color: $color-brand-primary-default !important;
        }
    }

    .notification-remove {
        display: flex;
        justify-content: center;
        align-items: center;

        > svg {
            &:hover {
                background-color: red;
                cursor: pointer;
            }
        }
    }

    .notification-dropdown-item {
        display: block;
        word-wrap: break-word;
        white-space: normal;
    }

    .notification-container {
        background-color: #555;
        color: white;
        text-decoration: none;
        padding: 15px 26px;
        position: relative;
        display: inline-block;
        border-radius: 2px;
    }
}
