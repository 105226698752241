@import "assets/styles/colors";
@import "assets/styles/typography";

.react-datepicker-popper {
    z-index: 10;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker {
    font-family: $font-family !important;

    &__header {
        background-color: $color-white;
        border-bottom: none;
    }

    &__input-container > input {
        @extend .regular-14;
        border: 1px solid $color-gray-lightest;
        background-color: $color-extra-light;
        padding: 0.375rem 0.75rem;
        height: 40px;
        width: 100%;
        border-radius: 8px;

        &::placeholder {
            color: $color-gray-dark !important;
        }

        &:focus {
            outline-width: 0px;
            border-color: $color-brand-primary-default;
        }
    }

    &__day-name {
        color: $color-gray-light;
        border-bottom: none;
    }

    &__triangle {
        border-bottom-color: $color-white !important;
    }

    &__navigation--previous {
        border-right-color: $color-brand-primary-default;
    }

    &__navigation--next {
        border-left-color: $color-brand-primary-default;
    }

    &__day--selected,
    &__day--keyboard-selected,
    &__month--selected {
        background-color: $color-brand-primary-default !important;
    }

    &-year-header {
        @extend .bold-12;
    }

    &__month-wrapper {
        @extend .semibold-14;
        min-width: max-content;
        white-space: nowrap;

        .react-datepicker__month-text {
            &:first-child {
                text-align: left;
                padding-left: 8px;
            }

            &:last-child {
                text-align: right;
                padding-right: 8px;
            }
        }
    }
}
