@import "assets/styles/colors";
@import "assets/styles/typography";

/* Buttons */
$font-family: "Muli", sans-serif;

%buttons-shared {
    @extend .semibold-12;

    border-radius: 8px !important;
    text-align: center !important;
    padding: 6px 6px !important;
    min-height: 40px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

button:focus {
    outline-width: 0px;
}

.btn-success {
    @extend %buttons-shared;

    color: $color-white !important;
    background-color: $color-brand-primary-default !important;
    border: 1px solid $color-brand-primary-default !important;
    //box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16) !important;
    text-align: center;

    &:hover,
    &:focus {
        background-color: $color-brand-primary-dark !important;
    }

    &.disabled,
    &:disabled {
        background-color: $color-brand-secondary-light !important;
        border-color: $color-brand-secondary-light !important;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.btn-secondary {
    @extend %buttons-shared;

    //box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
    color: $color-white !important;
    background-color: $color-brand-secondary-default !important;

    &:hover,
    &:focus {
        background-color: $color-brand-secondary-dark !important;
    }

    &.disabled,
    &:disabled {
        background-color: $color-brand-secondary-light !important;
        border-color: $color-brand-secondary-light !important;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.btn-default {
    background-color: $color-white;
    border: 0.5px solid $color-white;
    box-sizing: border-box;
    border-radius: 8px;

    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    color: $color-gray;

    &.disabled,
    &:disabled {
        background-color: $color-brand-secondary-light !important;
        border-color: $color-brand-secondary-light !important;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.btn-default.active {
    background-color: $color-brand-primary-default !important;
    border: 0.5px solid $color-brand-primary-default !important;
    //box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16) !important;
    border-radius: 8px !important;

    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $color-white;
}

.btn-outline-success {
    @extend %buttons-shared;

    border: 1px solid $color-brand-primary-default !important;
    color: $color-brand-primary-default !important;
    box-sizing: border-box !important;
    background-color: $color-white !important;

    &:hover,
    &:focus {
        border-color: $color-brand-primary-dark !important;
        color: $color-brand-primary-dark !important;
    }

    &.disabled,
    &:disabled {
        border-color: $color-brand-secondary-light !important;
        color: $color-brand-secondary-light !important;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.btn-link {
    @extend %buttons-shared;

    box-shadow: none !important;

    //border: 1px solid $color-brand-primary-default !important;
    //color: $color-brand-primary-default !important;
    //box-sizing: border-box !important;
    //background-color: $color-white !important;

    &:hover,
    &:focus {
        //border-color: $color-brand-primary-dark !important;
        //color: $color-brand-primary-dark !important;
    }

    &.disabled,
    &:disabled {
        //border-color: $color-brand-secondary-light !important;
        //color: $color-brand-secondary-light !important;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.btn-outline-secondary {
    @extend %buttons-shared;

    border: 1px solid $color-brand-secondary-default !important;
    color: $color-brand-secondary-default !important;
    box-sizing: border-box !important;
    background-color: $color-white !important;

    &:hover,
    &:focus {
        border-color: $color-brand-secondary-dark !important;
        color: $color-brand-secondary-dark !important;
    }

    &.disabled,
    &:disabled {
        border-color: $color-brand-secondary-light !important;
        color: $color-brand-secondary-light !important;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.btn-danger {
    @extend %buttons-shared;
    color: $color-white !important;

    &.disabled,
    &:disabled {
        background-color: $color-brand-secondary-light !important;
        border-color: $color-brand-secondary-light !important;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.btn-auth {
    @extend .regular-12;

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #4b506d !important;
    background-color: #ffffff !important;
    border: 1px solid #dfe0eb !important;
    box-sizing: border-box !important;
    border-radius: 100px !important;
    letter-spacing: 0.1px !important;
    margin-left: 2px;
    margin-right: 2px;

    &.active,
    &:hover,
    &:active,
    &:focus {
        color: #4b506d !important;
        background-color: #ffffff !important;
        border: 1px solid #46a72a !important;
        //box-shadow: none !important;
    }
}

/* End buttons */
