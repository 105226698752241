@import "assets/styles/colors.scss";

.sales-card-body {
    padding: 0px;
    overflow-y: auto;
    min-height: 230px; // So row MenuCell with 4 options don't get cropped.
}

table.sales-table {
    > tbody > tr > td {
        &.reset-expansion-style {
            padding: 0px !important;

            .row-expansion-style {
                padding: 0px;
            }
        }
    }

    .period-col,
    .period-col-header {
        width: 210px;

        .expand-icon path {
            fill: $color-info;
        }
    }

    .submitted-col,
    .submitted-col-header {
        width: 110px;
    }

    .status-col,
    .status-col-header {
        width: 180px;
        text-align: center;

        .status-cell {
            width: 180px;
            display: inline-block;
        }
    }

    .invoice-col,
    .invoice-col-header {
        width: 100px;
    }

    .action-col,
    .action-col-header {
        width: 25px;
        text-align: right;
    }
}

.sales-repair-table {
    margin-bottom: 0px;
}

.sales-expanded-row {
    background-color: $color-info-light;
}
