@import "assets/styles/colors";


.additional-deposit-report-view {
    .additional-deposit-report-view__card {
        margin-top: 16px;
    
        .card-header {
            background: $color-white;
            border-width: 0px;
            padding-left: 32px;
            padding-right: 32px;
        }
    }
    
    .react-bootstrap-table {
        table.sales-details-table {
            tr {
                td:first-child {
                    padding-left: 32px;
                }
    
                td:last-child {
                    padding-right: 32px;
                }
            }
    
            tfoot > tr > th {
                border-bottom-width: 0px !important;
    
                &:last-child {
                    padding-right: 32px;
                }
            }
        }
    }

}
