.brand-logo-text-wrapper {
    .brand-logo {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .brand-text {
        font-family: Muli, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 19px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.4px;
        color: #a4a6b3;
        opacity: 0.7;
        margin-bottom: 32px;
    }

    &:hover {
        p {
            opacity: 1;
        }
    }
}
