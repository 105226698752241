@import "assets/styles/buttons";

.contract-btn {
    margin-top: 29px;
}

.contract-link {
    color: #297cfd !important;
    cursor: pointer;
    font-size: 14px !important;
    letter-spacing: 0.3px !important;
    height: 38px;
    display: flex;
    align-items: center;
    background-color: rgba(240, 241, 247, 0.4);
    border-color: rgba(240, 241, 247, 0.4);
    overflow: hidden;
}

label[for="file-input"] {
    display: block;
    margin-bottom: 1em;
    font-size: 1em;
    color: #fff;
    opacity: 0.9;
    font-weight: bold;
}

input[type="file"] {
    cursor: pointer !important;
    border: none;
}

input[type="file"]::-webkit-file-upload-button {
    @extend %buttons-shared;

    border: 1px solid $color-brand-primary-default !important;
    color: $color-brand-primary-default !important;
    box-sizing: border-box !important;
    background-color: $color-white !important;

    &:hover {
        border-color: $color-brand-primary-dark !important;
        color: $color-brand-primary-dark !important;
    }

    &.disabled,
    &:disabled {
        border-color: $color-brand-secondary-light !important;
        color: $color-brand-secondary-light !important;
        pointer-events: none;
        cursor: not-allowed;
    }
}

input[type="file"]::-ms-browse {
    border: none;
    padding: 5px 12px;
    background: #fff;
    color: $color-brand-primary-default;
    font-size: 1em;
    transition: all 0.4s;
    cursor: pointer;
    border-radius: 20px;
}
