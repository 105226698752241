@import "assets/styles/colors";
@import "assets/styles/typography.scss";

$comments-border-color: $color-gray-light;

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@mixin rotate {
  animation: rotation 1s infinite linear;
}

.comments {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    //min-height: 200px;
    padding: 10px 10px 8px 10px;

    background-color: $color-bg;
    border: 1px solid $comments-border-color;
    box-shadow: 0px 2px 16px rgba(153, 155, 168, 0.12);
  }
  
  .comments__header {
    text-align: left;

    span {
      @extend .bold-16;
    }
  }

  .comments__divider {
    width: 100%;
    background-color: $comments-border-color;
    opacity: 0.3;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .comments__alerts {
    margin-top: 8px;
    /* Compensate for Alerts component */
    margin-bottom: -16px;
  }

  .comments__create {
    margin-top: 6px;
  }
  
  .comments__not-initialized {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    color: $color-gray-light;
  }

.comments__load-more {
  margin: 8px auto;
  width: fit-content;

  button {
    @extend .semibold-14;
    //color: $color-white
    background: none;
    color: $color-brand-primary-default;
    border-color: transparent;
    padding: 4px 8px;
    
    &:hover, &:focus, &:active {
      border: 2px solid currentColor;
      border-radius: 8px;
    }
  }
}

.comments__collapsed-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 8px;
}

.comments__last-update {
  @extend .regular-12;
  color: $color-gray;
  cursor: pointer !important;

  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 8px 0px 0px 8px;

  svg {
    height: 16px !important;
    width: 16px !important;
  }

  &--loading {
    svg {
      @include rotate;
    }
  }
}

.comments {
  *::-webkit-scrollbar {
    width: 8px;
    background: none;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: $color-brand-primary-default;
  }
  *::-webkit-scrollbar-button:no-button {
    display: none;
  }
  * {
    scrollbar-width: thin;
    scrollbar-color: $color-brand-primary-default rgba(0, 0, 0, 0.01);
  }
}
