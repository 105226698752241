@import "assets/style.scss";

.import-sales-report-modal {
    .modal-content {
        border-radius: 12px !important;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    }

    .modal-body {
        padding: 32px;
    }

    .header-section {
        margin-bottom: 48px;
    }

    .modal-title {
        @extend .bold-19;
        color: $color-black !important;
    }

    .modal-close-button {
        @extend .regular-12;
        cursor: pointer;
        color: $color-gray !important;

        path {
            stroke: $color-gray;
        }
    }

    .selected-file-row {
        align-items: center;
    }

    .remove-file-button rect {
        fill: $color-error;
    }

    .selected-file-section {
        margin-top: 22px;
        margin-bottom: 32px;
    }

    .selected-file-header {
        @extend .bold-12;
        color:  $color-black !important;
        margin-bottom: 18px;
    }

    .selected-file {
        @extend .regular-14;
        border-bottom: 1px solid $color-divider;
        padding-bottom: 13px;
        margin: 0px;
    }

    .modal-cancel-button {
        @extend .semibold-14;
        color: $color-brand-primary-default;
    }

    .modal-import-button {
        @extend .semibold-14;

        &:disabled {
            cursor: not-allowed;
        }
    }
}