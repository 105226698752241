.navbar__items-right {
    min-width: 250px;
    justify-content: flex-end;
}

@media (min-width: 992px) {
    .navbar__items-right {
        min-width: 460px;
    }
}
