@import "assets/styles/colors.scss";
/* TABLES */

.react-bootstrap-table {
    table {
        margin-bottom: 0px;

        > thead {
            > tr {
                > th {
                    font-family: Muli, sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    letter-spacing: 0.1px;
                    color: $color-brand-primary-default;
                    background: $color-white;
                    vertical-align: middle !important;
                    border-top: 1px solid $color-divider;
                    border-bottom: 1px solid $color-divider !important;
                    padding-top: 32px;
                    padding-bottom: 32px;

                    > label {
                        margin-bottom: 0;
                    }

                    &:first-child {
                        padding-left: 32px !important;
                    }

                    &:last-child {
                        padding-right: 32px !important;
                    }

                    &:focus {
                        outline-width: 0px;
                    }
                }
            }
        }

        &.no-top-border > thead > tr > th {
            border-top-width: 0px !important;
        }

        > tbody {
            > tr {
                > td {
                    height: 88px !important;
                    vertical-align: middle !important;

                    &:first-child {
                        padding-left: 32px;
                    }

                    &:last-child {
                        padding-right: 32px;
                    }
                }
            }
        }

        .frozen-first-column {
            position: sticky;
            left: 0;
            z-index: 2;
            background-color: $color-white;
            background-clip: padding-box; // fixes cell-border disappearing in firefox because of background-color
        }

        &.fixed-header {
            > thead > tr > th {
                position: sticky;
                top: 0px;
                z-index: 3;
                margin-bottom: 1px;

                &.frozen-first-column {
                    z-index: 4;
                }
            }
        }

        &.header-bottom-shadow-border > thead > tr > th {
            box-shadow: 0 1px 0 $color-divider;
        }
    }
}

/* END TABLES */
// Auto width to table columns
@media (min-width: 1200px) {
    .react-bootstrap-table table {
        table-layout: auto !important;
    }
}

.table-layout-auto {
    table-layout: auto !important;
}
