@import "assets/styles/colors.scss";

.packages-card {
    border-radius: 0 !important;

    .packages-table-wrapper {
        height: 1000px;
    }
}

.page-item > a {
    color: #9fa2b4 !important;
}

.active.page-item > a {
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    background-color: #9fa2b4 !important;
    border-color: #9fa2b4 !important;
    color: #ffffff !important;
}

#pageDropDown {
    background-color: #9fa2b4 !important;
    border-color: #9fa2b4 !important;
    font-family: Muli, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #ffffff;
}

.pagination {
    float: right;
}
