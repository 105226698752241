@import "assets/styles/colors";
/* Typography */
$font-family: "Muli", sans-serif;

%regular-shared {
    font-family: $font-family !important;
    color: $color-gray-dark;

    font-style: normal !important;
    font-weight: normal !important;
}

.title {
    @extend %regular-shared;
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 30px !important;
    letter-spacing: 0.3px !important;
    color: #252733 !important;
}

.regular {
    &-16 {
        @extend %regular-shared;
        font-size: 16px !important;
        line-height: 20px !important;
        letter-spacing: 0.2px !important;
    }

    &-14 {
        @extend %regular-shared;
        font-size: 14px !important;
        line-height: 20px !important;
        letter-spacing: 0.3px !important;
    }

    &-12 {
        @extend %regular-shared;
        font-size: 12px !important;
        line-height: 20px !important;
        letter-spacing: 0.1px !important;
    }

    &-10 {
        @extend %regular-shared;
        font-size: 10px !important;
        line-height: 20px !important;
        letter-spacing: 0.1px !important;
    }
}

%semibold-shared {
    font-family: $font-family !important;
    color: $color-gray-dark;
    font-style: normal !important;
    font-weight: 600 !important;
}

.semibold {
    &-24 {
        @extend %semibold-shared;
        font-size: 24px !important;
        line-height: 30px !important;
        letter-spacing: 1px !important;
    }

    &-19 {
        @extend %semibold-shared;
        font-size: 19px;
        line-height: 24px;
        letter-spacing: 0.4px;
    }

    &-16 {
        @extend %semibold-shared;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

    &-14 {
        @extend %semibold-shared;
        font-size: 14px !important;
        line-height: 20px !important;
        letter-spacing: 0.2px !important;
    }

    &-14.active {
        @extend %semibold-shared;
        @extend .semibold-14;
        color: $color-success !important;
    }

    &-12 {
        @extend %semibold-shared;
        font-size: 12px !important;
        line-height: 15px !important;
        letter-spacing: 0.1px !important;
    }
}

%bold-shared {
    font-family: $font-family !important;
    color: $color-gray-dark !important;

    font-style: normal !important;
    font-weight: bold !important;
}

.bold {   
    &-40 {
        @extend %bold-shared;
        font-size: 40px !important;
        line-height: 50px !important;
        letter-spacing: 1px !important;
    }
    
    &-24 {
        @extend %bold-shared;
        font-size: 24px !important;
        line-height: 30px !important;
        letter-spacing: 0.3px !important;
    }

    &-19 {
        @extend %bold-shared;
        font-size: 19px;
        line-height: 24px;
        letter-spacing: 0.4px;
    }

    &-16 {
        @extend %bold-shared;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.2px;
    }

    &-14 {
        @extend %bold-shared;
        font-size: 14px !important;
        line-height: 18px !important;
        letter-spacing: 0.2px !important;
    }

    &-12 {
        @extend %bold-shared;
        font-size: 12px !important;
        line-height: 15px !important;
        letter-spacing: 0.3px !important;
        text-transform: uppercase !important;
    }

    &-11 {
        @extend %bold-shared;
        font-weight: 800 !important;
        font-size: 11px !important;
        line-height: 14px !important;
        letter-spacing: 0.5px !important;
        text-transform: uppercase !important;
    }
}

.regular-16, .regular-14, .regular-12, .regular-10,
.semibold-24, .semibold-16, .semibold-14, .semibold-12,
.bold-40, .bold-24, .bold-19, .bold-16, .bold-14, .bold-12, .bold-11 {
    &.empty-value, &.text-muted{
        color: $color-gray;
    }
}