@import "assets/style.scss";

.dropzone-container {
    @extend .regular-14;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: $color-divider;
    border-style: dashed;
    background-color: $color-extra-light;
    color: $color-gray;
    outline: none;
    transition: border .24s ease-in-out;

    &.is-drag-accept {
        border-color: $color-success;
    }

    &.is-drag-reject {
        border-color: $color-error;
    }

    &.is-drag-active {
        border-color: $color-info;
    }
}