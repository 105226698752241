@import "assets/styles/colors.scss";

.contract-modal {
    min-width: 900px;
}

.modal-header {
    padding: 32px;
    border-bottom: none;
}

.collapse-row {
    padding: 32px;
}

.wrapper {
    padding: 32px;
}

.alert-wrapper {
    padding: 32px 32px 0 32px;
}

.collapse-row:first-child {
    cursor: pointer;
    border-top: 1px solid #DFE0EB;
    border-bottom: 1px solid #DFE0EB;
}

.collapse-row:last-child {
    cursor: pointer;
    border-bottom: 1px solid #DFE0EB;
}

.auth-option {
    width: 100px;
}

.contrat-terms-text:after {
    color: $color-error;
    content: " *";
}