@import "assets/styles/typography.scss";
@import "assets/styles/colors.scss";

.custom-checkbox {
    
    & .custom-checkbox-svg {
        cursor: pointer;
        vertical-align: middle;
        width: 16px;
        height: 16px;
    }

    & .custom-checkbox-label {
        @extend .regular-12;
        margin-left: 8px;

        &.custom-required:after {
            content: " *";
            color: $color-error;
        }
    }

    & .custom-checkbox-unchecked circle {
        stroke: $color-gray-light;
    }

    & .custom-checkbox-checked circle {
        fill: $color-info;
    }

    &.custom-is-valid {
        & .custom-checkbox-unchecked circle {
            stroke: $color-brand-primary-default;
        }

        & .custom-checkbox-checked circle {
            fill: $color-brand-primary-default;
        }
    }

    &.custom-is-invalid {
        & .custom-checkbox-unchecked circle {
            stroke: $color-error;
        }
        & .custom-checkbox-checked circle {
            fill: $color-error;
        }
    }

    &.custom-is-disabled {
        & .custom-checkbox-svg {
            cursor: default;
        }

        & .custom-checkbox-unchecked circle {
            stroke: $color-brand-secondary-light;
        }
        
        & .custom-checkbox-checked circle {
            fill: $color-brand-secondary-light;
        }

        & .custom-checkbox-label {
            color: $color-brand-secondary-light;

        }
    }

    &.custom-is-readonly {
        & .custom-checkbox-svg {
            cursor: default;
        }
    }
}
