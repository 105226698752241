@import "assets/styles/colors";
@import "assets/styles/typography.scss";

.comment {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;

  padding: 4px 0px;
  text-align: left;
}

.comment__wrapper {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

.comment__body {
  @extend .regular-14;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  white-space: pre-wrap;
  border-radius: 12px;
  padding: 8px;
  padding-left: 18px;

  overflow-wrap: anywhere;
}

.comment__info {
  @extend .regular-12;
  @extend .text-muted;

  display: flex;
  justify-content: flex-start;

  margin: 0px 14px;
}

.comment--default {
  flex-direction: row-reverse;

  .comment__body {
    background-color: $color-white;
    color: $color-gray-dark;
    border-bottom-left-radius: 0px;
  }
}

.comment--active {
  flex-direction: row;

  .comment__wrapper {
    padding-right: 5px;
  }

  .comment__body {
    background-color: $color-brand-primary-default;
    color: $color-white;
    border-bottom-right-radius: 0px;
  }

  .comment__info {
    justify-content: flex-end;
  }
}

.comment--system {
  .comment__body {
    background-color: $color-gray;
    color: $color-white;
    border-bottom-left-radius: 0px;
  }
}

.comment__delete-wrapper {
  display: flex;
  align-items: baseline;
  $delete-icon-color: $color-error;

  .comment__delete-button {
    border: none;
    background: none;
    color: $delete-icon-color;
    
    &:hover {
      // Hover changes needed because of CustomPagination.scss selector
      color: $delete-icon-color;
      path {
        stroke: none;
      }
    }
  } 
}
